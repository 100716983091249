import type {PropsWithChildren} from 'react';

import Typography, {
  type TEXT_SIZES,
} from '@/components/base/elements/Typography/Typography';

interface StaticHeadingProps {
  size: (typeof TEXT_SIZES)[number];
}

export default function StaticHeading({
  children,
  size,
}: PropsWithChildren<StaticHeadingProps>) {
  return (
    <div className="sr-only js-disabled:not-sr-only motion-reduce:not-sr-only">
      <Typography as="h1" size={size} className="text-dsp mb-lg md:mb-2xl">
        {children}
      </Typography>
    </div>
  );
}
