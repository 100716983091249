import {useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';

// Components
import AppGrid from '@/pages/shopify.com/($locale)/_index/components/AppsSection/components/AppGrid';
import HeadingGroup from '@/pages/shopify.com/($locale)/_index/components/HeadingGroup/HeadingGroup';
import InteractiveGradient from '@/pages/shopify.com/($locale)/_index/components/InteractiveGradient/InteractiveGradient';
import Section from '@/pages/shopify.com/($locale)/_index/components/Section/Section';
// Hooks
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import useReducedMotion from '@/hooks/useReducedMotion';
// Types
import type {HomeHeadingGroupProps} from '@/pages/shopify.com/($locale)/_index/components/HeadingGroup/HeadingGroup';

import AppGroup from './components/AppGroup';

export interface AppsSectionProps {
  headingGroup: HomeHeadingGroupProps;
  sectionName: string;
  appsBg: string;
  alt: string;
  isWideHeading: boolean;
}

export default function AppsSection({
  headingGroup,
  sectionName,
  appsBg,
  alt,
  isWideHeading,
}: AppsSectionProps) {
  const prefersReducedMotion = useReducedMotion();
  const [hasIntersectedForAnimation, setHasIntersectedForAnimation] =
    useState(false);
  const [isManuallyPaused, setIsManuallyPaused] = useState(false);
  const [loadBackgroundImage, setLoadBackgroundImage] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const headingContainerRef = useRef<HTMLDivElement>(null);

  /**
   * Gather the position of the heading group to determine the boundary of the app grid
   */
  useEffect(() => {
    function getHeadingRect() {
      if (!headingContainerRef.current) return;
      const headingContainer = headingContainerRef.current?.querySelector(
        '.apps-heading-group',
      );
      if (!headingContainer) return null;
    }

    getHeadingRect();

    const onResize = debounce(getHeadingRect, 200);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [headingContainerRef, headingGroup]);

  /**
   * Use intersection observer to tell us when to:
   * - Animate the apps the first time they come into view
   * - Pause the animation when the container is not in view
   *
   * None if this is needed if the user has reduced motion enabled
   */
  const observerCallbackForAnimation = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (prefersReducedMotion) return;

      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          setHasIntersectedForAnimation(true);
        }
      });
    },
    [prefersReducedMotion],
  );

  useIntersectionObserver(containerRef, observerCallbackForAnimation, {
    threshold: 0.25,
  });

  const observerCallbackForBackground = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting && !loadBackgroundImage) {
          setLoadBackgroundImage(true);
        }
      });
    },
    [loadBackgroundImage],
  );

  useIntersectionObserver(containerRef, observerCallbackForBackground, {
    threshold: 0.1,
    rootMargin: '50% 0%',
  });

  return (
    <div className="bg-black">
      <Section
        className="bg-deep-navy pb-3xl overflow-hidden"
        radius="top"
        topSpacing="none"
        bottomSpacing="none"
        sectionName={sectionName}
        data-component-name="home-apps-section"
      >
        <div
          className="overflow-container relative w-screen overflow-clip"
          ref={headingContainerRef}
        >
          <div className="container relative z-10">
            <HeadingGroup
              {...headingGroup}
              className={cn(
                'apps-heading-group',
                'max-w-[33rem] pb-0 mt-2xl',
                'transition-opacity duration-1000',
                'md:absolute',
                isWideHeading ? 'md:max-w-[49rem]' : 'md:max-w-[38rem]',
                hasIntersectedForAnimation
                  ? 'md:motion-safe:opacity-100'
                  : 'md:motion-safe:opacity-0',
              )}
              headingClassName="mb-md text-pretty"
              subheadClassName="!text-b3 text-gray-d"
            />
          </div>
          <div
            aria-label={alt}
            className="relative group mt-xl w-screen mb-px"
            onClick={() => setIsManuallyPaused(!isManuallyPaused)}
            ref={containerRef}
          >
            <AppGrid hasIntersectedForAnimation={hasIntersectedForAnimation}>
              <AppGroup
                background={appsBg}
                hasIntersectedForAnimation={hasIntersectedForAnimation}
                loadBackgroundImage={loadBackgroundImage}
              />
              <AppGroup
                background={appsBg}
                hasIntersectedForAnimation={hasIntersectedForAnimation}
                loadBackgroundImage={loadBackgroundImage}
              />
            </AppGrid>
            <InteractiveGradient
              backgroundColor="#000a1e" // deep navy
              className="mix-blend-darken transition-opacity duration-1000 opacity-0 group-hover:opacity-60"
              containerRef={containerRef}
            />
          </div>
          {/* Top & bottom gradient overlays to fade out the apps */}
          <div className="pointer-events-none absolute w-full top-0 h-1/4 bg-gradient-to-b from-deep-navy to-transparent" />
          <div className="pointer-events-none absolute w-full bottom-0 h-1/4 bg-gradient-to-t from-deep-navy to-transparent" />
        </div>
      </Section>
    </div>
  );
}
