import Typography, {
  type TEXT_SIZES,
} from '@/components/base/elements/Typography/Typography';
import Kicker from '@/components/base/modules/HeadingGroup/Kicker';
import {twMerge} from '@/stylesheets/twMerge';
import type {HeadingGroupProps} from '@/components/base/modules/HeadingGroup/HeadingGroup';

export interface HomeSectionHeadingGroupProps extends HeadingGroupProps {
  headingClassName?: string;
  kickerClassName?: string;
  subheadClassName?: string;
  subheadSize?: (typeof TEXT_SIZES)[number];
}

export default function HomeSectionHeadingGroup({
  headingAs = 'h3',
  className,
  headingClassName,
  headingHtml,
  kicker,
  kickerClassName,
  size = 't3',
  subheadClassName,
  subheadHtml,
  subheadSize = 'body-sm',
}: HomeSectionHeadingGroupProps) {
  return (
    <div className={className}>
      {kicker && (
        <Kicker
          className={twMerge(
            'text-t7 font-normal normal-case text-cyan mb-sm text-pretty',
            kickerClassName,
          )}
        >
          {kicker}
        </Kicker>
      )}
      <div className="flex flex-wrap sm:flex-nowrap sm:items-baseline">
        <div className="w-full pb-md sm:w-2/3 lg:w-3/5 sm:pb-0 sm:pr-lg">
          {headingHtml && (
            <Typography
              size={size}
              as={headingAs}
              className={twMerge('inline-block text-pretty', headingClassName)}
            >
              {headingHtml}
            </Typography>
          )}
        </div>
        <div className="w-full sm:w-1/3 lg:w-2/5">
          {subheadHtml && (
            <Typography
              className={twMerge(
                'text-gray-c text-pretty inline-block',
                subheadClassName,
              )}
              size={subheadSize}
            >
              {subheadHtml}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
