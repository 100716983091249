import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import Typography, {
  TEXT_SIZES,
} from '@/components/base/elements/Typography/Typography';
import {
  getLongestString,
  getTransitionDelay,
} from '@/pages/shopify.com/($locale)/_index/components/AnimatedHeadingGroup/utils';

interface AnimatedHeadingProps {
  animationOffset: string;
  headingFixedPhrase?: string;
  headingAnimatedPhrases: string[];
  isPaused?: boolean | null;
  size: (typeof TEXT_SIZES)[number];
}

export default function AnimatedHeading({
  animationOffset,
  headingFixedPhrase,
  headingAnimatedPhrases,
  isPaused = false,
  size,
}: AnimatedHeadingProps) {
  // State
  const [headingLoopIndex, setHeadingLoopIndex] = useState(0);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isServerSide, setIsServerSide] = useState(true);

  // Refs
  const animationContainerRef = useRef<HTMLDivElement>(null);

  // Context variables
  const phraseCount = headingAnimatedPhrases.length;
  const longestPhrase = useMemo(
    () => getLongestString(headingAnimatedPhrases),
    [headingAnimatedPhrases],
  );
  const outgoingWordLength = useMemo(() => {
    const outgoingWord =
      headingAnimatedPhrases[(headingLoopIndex - 1) % phraseCount];

    return outgoingWord ? outgoingWord.split(' ').length : 0;
  }, [headingLoopIndex, phraseCount, headingAnimatedPhrases]);

  /**
   * Show the next word in the list of animated phrases.
   */
  const incrementAnimatedPhrase = useCallback(() => {
    const nextPhrase = (headingLoopIndex + 1) % phraseCount;
    setIsTransitioning(true);
    setHeadingLoopIndex(nextPhrase);
  }, [headingLoopIndex, phraseCount]);

  /**
   * When the component is in view and not actively transitioning,
   * increment the animated phrase.
   */
  useEffect(() => {
    if (!isIntersecting || isTransitioning || isPaused) return;
    incrementAnimatedPhrase();
  }, [isIntersecting, isTransitioning, isPaused, incrementAnimatedPhrase]);

  /**
   * Check if component is in view
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!isIntersecting && entry.isIntersecting) {
            setIsIntersecting(entry.isIntersecting);
          }
        });
      },
      {
        rootMargin: animationOffset,
      },
    );

    observer.observe(animationContainerRef.current!);

    return () => {
      observer.disconnect();
    };
  }, [animationOffset, isIntersecting]);

  useEffect(() => {
    setIsServerSide(false);
  }, []);

  return (
    <div aria-hidden className="motion-reduce:hidden js-disabled:hidden">
      {headingFixedPhrase && (
        <Typography as="div" size={size} className="text-dsp">
          {headingFixedPhrase}
        </Typography>
      )}

      <div className="relative" ref={animationContainerRef}>
        {/**
         * PLACEHOLDER PHRASE
         * The longest of all phrases is rendered here as a placeholder.
         * It's hidden from the user and from screen readers,
         * but it ensures that the container holds to the correct height
         * and responds to reflow and language changes and all that good stuff.
         * */}
        <h2
          className={cn(
            TEXT_SIZES[size],
            'text-dsp opacity-0 user-select-none tracking-wide pointer-events-none mb-lg md:mb-2xl',
          )}
          aria-hidden
        >
          {longestPhrase}
        </h2>
        {headingAnimatedPhrases.map((headingPhrase, headingIndex) => {
          const isOutgoing =
            (phraseCount + headingLoopIndex - 1) % phraseCount === headingIndex;
          const isActive = headingLoopIndex === headingIndex;
          const isIncoming =
            (headingLoopIndex + 1) % phraseCount === headingIndex;

          return (
            <div
              className={twMerge(cn(TEXT_SIZES[size], 'text-dsp'))}
              key={`heading-${headingIndex}-${headingPhrase}`}
            >
              {/* Phrase container */}
              <span className="absolute top-0 pt-2 flex flex-wrap items-start justify-start gap-0">
                {headingPhrase.split(' ').map((word, wordIndex) => {
                  const transitionDelay = getTransitionDelay(
                    isActive,
                    wordIndex,
                    outgoingWordLength,
                  );
                  const isFinalWord =
                    wordIndex === headingPhrase.split(' ').length - 1;

                  /**
                   * Word
                   * Each word has two containers:
                   * - The outer container is used to hide overflow
                   * - The inner container is used to animate the in from the bottom and out from the top
                   */
                  return (
                    <span
                      className="inline-block overflow-hidden pb-2 -mt-2"
                      key={`heading-${headingIndex}-${headingPhrase}-${word}-${wordIndex}`}
                    >
                      <span
                        className={cn(
                          `inline-block m-0 p-0 transition-[transform,opacity] duration-[0.45s] ease-heading-transition-ease`,
                          {
                            'translate-y-100 opacity-0':
                              isIncoming || (!isActive && !isOutgoing),
                            'translate-y-0 opacity-100': isActive,
                            '-translate-y-100 opacity-0': isOutgoing,
                            // occasionally browser doesn't trigger repaint on page load and all words become visible one on the top of another until the animation starts
                            // the line below hides all words except the first line and show them as soon as JS runs, causing repaint
                            hidden: isServerSide && !isActive,
                          },
                        )}
                        onTransitionEnd={() => {
                          if (isFinalWord && isActive) {
                            setIsTransitioning(false);
                          }
                        }}
                        style={{
                          transitionDelay: `${transitionDelay}s`,
                        }}
                      >
                        {word}&nbsp;
                      </span>
                    </span>
                  );
                })}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
