import cn from 'classnames';

import CardGrid from '../CardGrid/CardGrid';
import CardGridCard, {type CardGridCardProps} from '../CardGrid/CardGridCard';
import HeadingGroup, {
  type HomeHeadingGroupProps,
} from '../HeadingGroup/HeadingGroup';
import HomeSection, {type HomeSectionProps} from '../Section/Section';

import MobileCard, {type MobileCardProps} from './MobileCard';

export interface DesktopAndMobileSectionProps {
  desktopCard: CardGridCardProps;
  headingGroup: HomeHeadingGroupProps;
  mobileCard: MobileCardProps;
  section?: HomeSectionProps;
}

export default function DesktopAndMobileSection({
  desktopCard,
  headingGroup,
  mobileCard,
  section,
}: DesktopAndMobileSectionProps) {
  if (desktopCard.image) {
    desktopCard.image.className = 'w-full h-full';
  }

  return (
    <HomeSection {...section} data-component-name="home-desktopmobile-section">
      <div className="container">
        <HeadingGroup {...headingGroup} headingClassName="text-pretty" />
        <CardGrid numberOfColumns={2}>
          <CardGridCard
            {...desktopCard}
            className="h-auto"
            numberOfColumns={2}
            backgroundStyles={[
              cn(
                'top-0 left-[6cqw] w-[65cqw] h-[41cqw] rounded-[405px] transform scale-200 -rotate-[30deg]',
                'bg-[radial-gradient(#286567,#0f3335_44%,#061a1c_70%,#061a1c_100%)]',
              ),
            ]}
          />
          <MobileCard
            {...mobileCard}
            backgroundStyles={[
              cn(
                'top-[12cqw] left-[16cqw] w-[72cqw] h-[42cqw] rounded-[340px] transform scale-200 -rotate-[4deg]',
                'bg-[radial-gradient(#235357,#0e2929_44%,#061a1c_70%,#061a1c_100%)]',
              ),
            ]}
          />
        </CardGrid>
      </div>
    </HomeSection>
  );
}
