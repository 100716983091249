import cn from 'classnames';

interface ConversionListItemProps {
  isActive: boolean;
  isLastItem: boolean;
  itemNumber: string;
  itemText: string;
  setActiveIndex: () => void;
}
export default function ConversionListItem({
  isActive,
  isLastItem,
  itemNumber,
  itemText,
  setActiveIndex,
}: ConversionListItemProps) {
  return (
    <p
      onFocus={setActiveIndex}
      onMouseEnter={setActiveIndex}
      className="cursor-default w-full text-left items-center flex mb-md"
      role="listitem"
      tabIndex={0}
    >
      <span className="block text-body-lg text-avocado w-10 md:w-16 pb-2 md:pb-[0.625rem]">
        {itemNumber}
      </span>
      <span
        className={cn(
          'block grow text-t4 border-b pb-2 md:pb-[0.625rem] transition-color duration-300 text-pretty',
          isLastItem && 'border-transparent',
          isActive ? 'text-white' : 'text-white sm:text-gray-c',
        )}
      >
        {itemText}
      </span>
    </p>
  );
}
