import {useRef, useState} from 'react';
import cn from 'classnames';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {twMerge} from '@/stylesheets/twMerge';
import HeadingGroup from '@/pages/shopify.com/($locale)/_index/components/HeadingGroup/HeadingGroup';
import Section from '@/pages/shopify.com/($locale)/_index/components/Section/Section';
import type {HomeHeadingGroupProps} from '@/pages/shopify.com/($locale)/_index/components/HeadingGroup/HeadingGroup';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import Typography from '@/components/base/elements/Typography/Typography';
import Image from '@/components/base/elements/Image/Image';
import type {ImageProps} from '@/components/base/elements/Image/Image';
import AutoResponsiveImage, {
  type AutoResponsiveImageProps,
} from '@/components/base/elements/AutoResponsiveImage/AutoResponsiveImage';

export type StatsSectionProps = {
  sectionName: string;
  headingGroup: HomeHeadingGroupProps;
  alt: string;
  images: {
    button: ImageProps;
    checkout: AutoResponsiveImageProps;
    customers: AutoResponsiveImageProps; // TODO: convert customers to array of images to polish animation
    customersMobile: ImageProps;
  };
  stats: {
    heading: string;
    icon: ImageProps;
    unit: string;
    value: string;
  }[];
  disclaimerHtml: string;
};

const TRANSITION_DURATION = 1000;
const TRANSITION_STEP = {
  IDLING: 'idling',
  INTERSECTED: 'intersected',
};

const idlingStyles = 'opacity-0 transition-all translate-y-4';
const intersectedStyles = 'opacicity-100 tranlsate-y-0';

export default function StatsSection({
  sectionName,
  headingGroup,
  alt,
  images,
  stats,
  disclaimerHtml,
}: StatsSectionProps) {
  const [transitionStep, setTransitionStep] = useState(TRANSITION_STEP.IDLING);
  const imagesRef = useRef<HTMLDivElement>(null);

  useIntersectionObserver(imagesRef, (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        setTransitionStep(TRANSITION_STEP.INTERSECTED);
      }
    });
  });

  const ellipseClassNames =
    'with-blurred-ellipse before:left-[-12.5%] before:rotate-[-20deg] before:top-[25%]';

  const transitionStyles =
    transitionStep === TRANSITION_STEP.IDLING
      ? idlingStyles
      : intersectedStyles;

  return (
    <Section
      bottomSpacing="none"
      className={cn(
        'bg-deep-pine py-3xl sm:pt-4xl sm:pb-2xl xl:pb-3xl',
        ellipseClassNames,
      )}
      sectionName={sectionName}
      topSpacing="2xl"
      data-component-name="home-stats-section"
    >
      <TwoColumn className="items-center z-20">
        <TwoColumn.Col1 className="sm:col-span-4 mb-2xl sm:mb-0">
          <HeadingGroup
            className="pb-0"
            headingAs="h3"
            headingHtml={headingGroup.headingHtml}
            size="t4"
          />
          <ul className="flex mt-4 space-x-4 md:mt-8 md:space-x-6">
            {stats.map((stat, index) => (
              <li
                key={index}
                className="border-t-[.5px] border-[#11352D] flex flex-col flex-grow justify-between pt-2 w-1/2"
              >
                <div className="[&_picture]:self-start flex font-mono items-center space-x-3 text-avocado uppercase">
                  <Image {...stat.icon} />
                  <Typography className="text-xs">{stat.heading}</Typography>
                </div>
                <div className="flex items-start mt-2 md:mt-4 whitespace-nowrap">
                  <Typography size="t1">{stat.value}</Typography>
                  <Typography
                    className={index > 0 ? 'leading-6 md:leading-10' : ''}
                    size="t4"
                  >
                    {stat.unit}
                  </Typography>
                </div>
              </li>
            ))}
          </ul>
          <HeadingGroup
            className="pb-0"
            subheadClassName="border-l-2 border-avocado pl-[1em] relative text-gray-b text-pretty xl:max-w-[85%]"
            subheadSize="body-sm"
            subheadHtml={headingGroup.subheadHtml}
          />
          <div
            className="text-gray-b text-b6"
            dangerouslySetInnerHTML={{__html: disclaimerHtml as string}}
          ></div>
        </TwoColumn.Col1>
        <TwoColumn.Col2 className="sm:col-span-3 sm:col-start-6">
          <div
            aria-label={alt}
            className="px-10 relative sm:px-0"
            ref={imagesRef}
          >
            <div
              aria-hidden="true"
              className={twMerge(
                cn(
                  'absolute hidden left-[-25%] top-[-25%] w-[125%] z-0 sm:flex',
                  transitionStyles,
                ),
              )}
              style={{
                transitionDuration: `${TRANSITION_DURATION}ms`,
              }}
            >
              <AutoResponsiveImage {...images.customers} sizes="50vw" />
            </div>
            <div
              aria-hidden="true"
              className={twMerge(
                cn(
                  'absolute flex -left-[var(--margin)] -right-[var(--margin)] -top-16 w-[calc(100%+(var(--margin)*2))] z-0 sm:hidden',
                  transitionStyles,
                ),
              )}
              style={{
                transitionDuration: `${TRANSITION_DURATION}ms`,
              }}
            >
              <Image {...images.customersMobile} />
            </div>
            <div
              aria-hidden="true"
              className={twMerge(
                cn(
                  'relative rounded-lg overflow-hidden z-10',
                  transitionStyles,
                ),
              )}
              style={{
                transitionDelay: `${TRANSITION_DURATION * 0.33}ms`,
                transitionDuration: `${TRANSITION_DURATION}ms`,
              }}
            >
              <AutoResponsiveImage
                {...images.checkout}
                sizes="(min-width: 640px) 50vw, 100vw"
              />
            </div>
            <div
              aria-hidden="true"
              className={twMerge(
                cn(
                  'absolute bg-[#5a31f4] bottom-[25%] px-4 py-2 right-7 rounded shadow-md w-[100px] z-10 sm:-right-3 md:w-[120px] lg:px-6 lg:py-3 lg:-right-4 lg:w-[170px]',
                  transitionStyles,
                ),
              )}
              style={{
                transitionDelay: `${TRANSITION_DURATION * 0.66}ms`,
                transitionDuration: `${TRANSITION_DURATION}ms`,
              }}
            >
              <Image {...images.button} />
            </div>
          </div>
        </TwoColumn.Col2>
      </TwoColumn>
    </Section>
  );
}
