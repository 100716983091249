import {type PropsWithChildren, forwardRef} from 'react';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import type {LinkProps} from '@/components/base/elements/Link/Link';
import Link from '@/components/base/elements/Link/Link';
import type {ButtonProps} from '@/components/base/elements/Button/Button';
import Button from '@/components/base/elements/Button/Button';
import {IconEnum} from '@/enums';
import Icon from '@/components/base/elements/Icon/Icon';

import HeadingGroup, {
  type HomeHeadingGroupProps,
} from '../HeadingGroup/HeadingGroup';

export enum CardBackgroundEnum {
  Default = 'default',
  MediumGreen = 'mediumGreen',
  None = 'none',
}

export interface CardProps {
  background?: CardBackgroundEnum;
  backgroundStyles?: string[];
  button?: ButtonProps;
  className?: string;
  componentName: string;
  headingGroup?: HomeHeadingGroupProps;
  headingGroupPosition?: 'top' | 'bottom';
  isFullWidth?: boolean;
  link?: LinkProps & {externalLink?: boolean};
  mediaContainerAspectRatio?: string;
  pill?: string;
  subheadClassName?: string;
  isContentRelative?: boolean;
  step?: string;

  /**
   * This card has a solid background when shown in Safari.
   */
  hasSolidBackgroundForSafari?: boolean;
}

const Card = forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
  function Card(
    {
      background = CardBackgroundEnum.Default,
      backgroundStyles,
      button,
      children,
      className,
      componentName,
      headingGroup,
      headingGroupPosition = 'bottom',
      isFullWidth = false,
      link,
      mediaContainerAspectRatio,
      pill,
      subheadClassName,
      isContentRelative = true,
      hasSolidBackgroundForSafari = false,
      step,
    }: PropsWithChildren<CardProps>,
    ref,
  ) {
    const hasBackground = background !== CardBackgroundEnum.None;
    const hasBackgroundStyles =
      backgroundStyles !== undefined && backgroundStyles.length > 0;
    const isDefaultCard = background === CardBackgroundEnum.Default;
    const isMediumGreenCard = background === CardBackgroundEnum.MediumGreen;

    const buttonMarkup = button ? (
      <Button
        mode="dark"
        intent="secondary"
        size="small"
        href={button.href}
        className="whitespace-nowrap"
      >
        {button.children}
      </Button>
    ) : null;

    let linkMarkup: JSX.Element | null = null;

    if (link) {
      linkMarkup = link?.externalLink ? (
        <Link href={link.href} arrow={false}>
          {link.text}
          &nbsp;
          <Icon
            size={16}
            icon={IconEnum.ExternalLinkArrow}
            className="inline w-[1em] h-[1em] align-baseline leading-[inherit] opacity-0 transition-all translate-x-[-.5em] ease-in-out duration-150 group-hover:translate-x-0 group-hover:opacity-100"
          />
        </Link>
      ) : (
        <Link href={link.href}>{link.text}</Link>
      );
    }

    return (
      <div
        data-component-name={componentName}
        className={twMerge(
          cn('rounded-xl overflow-hidden flex flex-col', {
            'h-[400px] sm:h-auto':
              !isFullWidth && (isDefaultCard || isMediumGreenCard),
            'flex-col-reverse': headingGroupPosition === 'top',
            'justify-between border-t border-hairline-green': hasBackground,
            'bg-card-gradient shadow-card': isDefaultCard,
            'shadow-medium-green-card bg-medium-green': isMediumGreenCard,
            'bg-deep-green': hasBackgroundStyles,
            'safari-blur': hasSolidBackgroundForSafari,
          }),
          className,
        )}
        style={{containerType: 'inline-size'}} // Tailwind doesn't support container queries out of the box
        ref={ref}
      >
        {backgroundStyles &&
          backgroundStyles.map((classes, index) => (
            <div
              key={`glow-${index}`}
              className={twMerge(
                `absolute w-full h-full top-0 left-0 z-0 glow-${index}`,
                classes,
              )}
            />
          ))}
        <div
          className={cn('z-10', {
            grow: hasBackground,
            relative: isContentRelative,
          })}
          style={{aspectRatio: mediaContainerAspectRatio || 'auto'}}
        >
          {pill && (
            <span className="absolute block z-20 bg-tag-shade top-md left-8 px-6 py-3 rounded-full">
              {pill}
            </span>
          )}
          {children && children}
        </div>
        {step && (
          <div className="flex items-center gap-x-3 pt-md text-[0.75rem] text-avocado after:w-full after:h-px after:bg-[#f0f0f0]/30">
            <span className="whitespace-nowrap">{step}</span>
          </div>
        )}
        {headingGroup && (
          <HeadingGroup
            {...headingGroup}
            className={twMerge(
              cn(
                'z-10 px-lg pb-lg pt-md sm:pt-lg max-w-[65ch] text-pretty [transform:translateZ(0)]',
                {
                  'px-0': !hasBackground,
                },
                headingGroup && headingGroup.className,
              ),
            )}
            headingAs={headingGroup.headingAs || 'h4'}
            headingClassName="mb-sm md:mb-xs text-pretty"
            size={headingGroup.size || 't7'}
            subheadSize={headingGroup.subheadSize || 'body-sm'}
            subheadClassName={twMerge(
              cn(
                {
                  'text-gray-a': isDefaultCard,
                  'text-gray-b': isMediumGreenCard,
                  'text-gray-c': !hasBackground,
                },
                subheadClassName,
              ),
            )}
          />
        )}
        {button || link ? (
          <div className="flex flex-wrap gap-2 items-center pb-6">
            {buttonMarkup}
            {linkMarkup}
          </div>
        ) : null}
      </div>
    );
  },
);

export default Card;
